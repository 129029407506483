<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'保险客户线索'"></sb-headline>

    <div class="billboards">
      <div id="dashboard" style="height: 300px;width: 100%"></div>
    </div>

    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <!-- 表格数据搜索 -->
      <template #header="params">
        <div class="search_box">
          <el-form :inline="true" :model="searchData" label-position="top">

            <el-form-item>
              <el-input v-model="searchData.customerName" placeholder="客户名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.mobile" placeholder="手机号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.licensePlate" placeholder="车牌" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.terminalSource" placeholder="终端来源" clearable>
                <el-option label="系统" value="0"></el-option>
                <el-option label="Android" value="1"></el-option>
                <el-option label="Ios" value="2"></el-option>
                <el-option label="微信" value="3"></el-option>
                <el-option label="门店通app" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.firstChannel" clearable placeholder="一级来源">
                <el-option :label="item.dataValue" :value="item.dataValue" v-for="(item,index) in bxClueFirstChannels" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.secondChannel" clearable placeholder="二级来源">
                <el-option :label="item.dataValue" :value="item.dataValue"  v-for="(item,index) in bxClueSecondChannels" :key="index"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-date-picker
                v-model="searchData.nextExpireDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                clearable
                placeholder="下次保险到期日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-date-picker
                v-model="searchData.registrationDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                clearable
                placeholder="注册日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-row :gutter="10">
            <el-col :span="6" :offset="18">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <!-- 表格顶部操作 -->
      <template #navBar>
        <div class="table_top_box">
          <div>共{{ customerTotal }}条线索</div>
        </div>
      </template>

      <span v-for="(item, index) in columnData" :key="item.customerClueId">
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-if="item.prop == 'customerClueId'"
                         fixed="left">
          <template slot-scope="scope">
            {{ scope.row.customerClueId }}
          </template>
        </el-table-column>

        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'terminalSource'">
          <template slot-scope="scope">
            {{ scope.row.terminalSource | terminalSourceFormatter }}
          </template>
        </el-table-column>

        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'isEffective'">
          <template slot-scope="scope">
            {{ scope.row.isEffective | isEffectiveFormatter }}
          </template>
        </el-table-column>


        <el-table-column v-else :label="item.label" :prop="item.prop" :min-width="item.width">
          <template slot-scope="scope">
            <div v-if="scope.row[item.prop] || scope.row[item.prop] === 0">{{ scope.row[item.prop] }}</div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </span>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
          <el-link type="primary" @click="openViewFn(scope.row)">查看</el-link>
          <el-link type="primary" @click="openEditFn(scope.row)" style="margin-left: 10px">编辑</el-link>
        </template>
      </el-table-column>
    </my-table>


    <el-drawer title="保险线索客户详情" :visible.sync="drawer" direction="rtl">
      <el-descriptions title="" border column="2">
        <el-descriptions-item label="客户线索Id">{{emptyToLine(customerDetail.customerClueId)}}</el-descriptions-item>
        <el-descriptions-item label="客户名称">{{emptyToLine(customerDetail.customerName)}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{emptyToLine(customerDetail.mobile)}}</el-descriptions-item>
        <el-descriptions-item label="住址">{{emptyToLine(customerDetail.address)}}</el-descriptions-item>
        <el-descriptions-item label="一级来源">{{emptyToLine(customerDetail.firstChannel)}}</el-descriptions-item>
        <el-descriptions-item label="二级来源">{{emptyToLine(customerDetail.secondChannel)}}</el-descriptions-item>
        <el-descriptions-item label="下次保险到期日期">{{emptyToLine(customerDetail.nextExpireDate)}}</el-descriptions-item>
        <el-descriptions-item label="注册日期">{{emptyToLine(customerDetail.registrationDate)}}</el-descriptions-item>
        <el-descriptions-item label="终端来源">{{customerDetail.terminalSource |terminalSourceFormatter}}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{emptyToLine(customerDetail.creator)}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{emptyToLine(customerDetail.ctime)}}</el-descriptions-item>
        <el-descriptions-item label="下个周期保险线索生成日期">{{emptyToLine(customerDetail.nextClueGenerateDate)}}</el-descriptions-item>
        <el-descriptions-item label="vin">{{emptyToLine(customerDetail.vin)}}</el-descriptions-item>
        <el-descriptions-item label="Logo"><el-image :src="customerDetail.vehicleLogo" style="width: 40px;height: 40px"></el-image></el-descriptions-item>
        <el-descriptions-item label="品牌">{{emptyToLine(customerDetail.brand)}}</el-descriptions-item>
        <el-descriptions-item label="车系">{{emptyToLine(customerDetail.vehicleSeries)}}</el-descriptions-item>
        <el-descriptions-item label="车系">{{emptyToLine(customerDetail.vehicleModel)}}</el-descriptions-item>
        <el-descriptions-item label="年款">{{emptyToLine(customerDetail.vehicleProductYear)}}</el-descriptions-item>
        <el-descriptions-item label="车辆类型">{{emptyToLine(customerDetail.vehicleType)}}</el-descriptions-item>
        <el-descriptions-item label="发动机号码">{{emptyToLine(customerDetail.engineNumber)}}</el-descriptions-item>
        <el-descriptions-item label="发证日期">{{emptyToLine(customerDetail.issueDate)}}</el-descriptions-item>
        <el-descriptions-item label="使用性质">{{emptyToLine(customerDetail.useNature)}}</el-descriptions-item>
        <el-descriptions-item label="签发机关">{{emptyToLine(customerDetail.issueAuthority)}}</el-descriptions-item>
        <el-descriptions-item label="线索有效性">{{ customerDetail.isEffective |isEffectiveFormatter}}</el-descriptions-item>
        <el-descriptions-item label="燃料类型">{{emptyToLine(customerDetail.fuelType)}}</el-descriptions-item>
        <el-descriptions-item label="能源类型">{{emptyToLine(customerDetail.energyType)}}</el-descriptions-item>
        <el-descriptions-item label="行驶证图片"><el-image v-if="customerDetail.vehicleLicense" :src="customerDetail.vehicleLicense"  :preview-src-list="[customerDetail.vehicleLicense]" style="width: 300px;height: 300px"></el-image></el-descriptions-item>
      </el-descriptions>
    </el-drawer>


    <el-drawer title="保险线索客户编辑" :visible.sync="editDrawer" direction="rtl" >
      <div style="display: flex;margin: 10px">
        <div>
          <el-image :src="customerEditForm.vehicleLicense"  :preview-src-list="[customerEditForm.vehicleLicense]" style="width: 300px;height: 300px"></el-image>
          <div style="margin: 10px;text-align: center">
            <el-button type="primary" @click="ocr(customerEditForm.vehicleLicense)">行驶证识别</el-button>
          </div>
        </div>
        <div style="flex: 2">
          <el-form label-width="120px" label-position="right" ref="customerEditForm" :model="customerEditForm" inline>
            <el-form-item label="客户名称">
              <el-input v-model="customerEditForm.customerName" placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="customerEditForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="vin">
              <el-input v-model="customerEditForm.vin" placeholder="请输入vin"></el-input>
            </el-form-item>
            <el-form-item label="品牌">
              <el-input v-model="customerEditForm.brand" placeholder="请输入品牌"></el-input>
            </el-form-item>
            <el-form-item label="车系">
              <el-input v-model="customerEditForm.vehicleSeries" placeholder="请输入车系"></el-input>
            </el-form-item>
            <el-form-item label="年款">
              <el-input v-model="customerEditForm.vehicleProductYear" placeholder="请输入年款"></el-input>
            </el-form-item>
            <el-form-item label="车辆类型">
              <el-input v-model="customerEditForm.vehicleType" placeholder="请输入车辆类型"></el-input>
            </el-form-item>
            <el-form-item label="发动机号码">
              <el-input v-model="customerEditForm.engineNumber" placeholder="请输入发动机号码"></el-input>
            </el-form-item>

            <el-form-item label="使用性质">
              <el-input v-model="customerEditForm.useNature" placeholder="请输入使用性质"></el-input>
            </el-form-item>
            <el-form-item label="签发机关">
              <el-input v-model="customerEditForm.issueAuthority" placeholder="请输入签发机关"></el-input>
            </el-form-item>
            <el-form-item label="住址">
              <el-input v-model="customerEditForm.address"  type="textarea" :rows="2" placeholder="请输入住址"></el-input>
            </el-form-item>
            <el-form-item label="注册日期">
              <el-date-picker
                v-model="customerEditForm.registrationDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="请输入选择注册日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发证日期">
              <el-date-picker
                v-model="customerEditForm.issueDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="请输入选择发证日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button @click="editDrawer = false">取 消</el-button>
            <el-button type="primary" @click="submitEditForm">确 定</el-button>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div style="margin: 10px" v-if="ocrResult">
        <el-link type="primary" @click="copyiInfo" style="float: right;margin-right: 20px">一键同步</el-link>
        <el-descriptions title="识别区域" :column="2">
          <el-descriptions-item label="VIN">{{ocrResult.vin}}</el-descriptions-item>
          <el-descriptions-item label="车型选择">
            <el-select v-model="modelCode" style="width: 300px !important;">
              <el-option :value="item.modelCode" :label="item.brandName + ' / '+ item.productYear + ' / ' + item.displacement + ' / ' + item.modelName  " v-for="(item,index) in ocrResult.vehicleModelList" :key="index"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="客户名称">{{ocrResult.owner }}</el-descriptions-item>
          <el-descriptions-item label="车牌号">{{ocrResult.licensePlate}}</el-descriptions-item>
          <el-descriptions-item label="居住地">{{ocrResult.address}}</el-descriptions-item>
          <el-descriptions-item label="注册日期">{{ocrResult.registrationDate}}</el-descriptions-item>

          <el-descriptions-item label="使用性质">{{ocrResult.useNature}}</el-descriptions-item>
          <el-descriptions-item label="发动机号码">{{ocrResult.engineNumber}}</el-descriptions-item>
          <el-descriptions-item label="车辆类型">{{ocrResult.vehicleType}}</el-descriptions-item>
          <el-descriptions-item label="发证日期">{{ocrResult.issueDate}}</el-descriptions-item>
          <el-descriptions-item label="签发机关">{{ocrResult.issueAuthority}}</el-descriptions-item>
        </el-descriptions>
      </div>

    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import Template from "@/views/dashboard/template.vue";
import * as echarts from 'echarts';

import {
  emptyToLine
} from "@/utils/common";
import row from "element-ui/packages/row";
import { right } from "core-js/internals/array-reduce";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Template },
  filters: {
    terminalSourceFormatter(val){
      switch (val){
        case 0:
          return "系统";
        case 1:
          return "Android";
        case 2:
          return "Ios";
        case 3:
          return "微信";
        case 4:
          return "门店通";
        default:
          return "-";
      }
    },
    isEffectiveFormatter(val){
      switch (val){
        case 0:
          return "无效";
        case 1:
          return "有效";
        default:
          return "-";
      }
    }
  },
  data() {
    //这里存放数据
    return {
      listData: [], //列表数据
      listLoading: false, //列表loading
      tabList: [],  //标签数据
      selectMainTab: "1",
      editDrawer: false,
      // 搜索数据
      searchData: {
        licensePlate: null,
        customerCode: null,
        mobile: null,
        firstChannel: null,
        secondChannel: null,
        customerName: null,
        nextExpireDate: null,
        registrationDate: null,
        terminalSource: null
      },
      drawer: false,
      customerTotal: "",
      // 表格column数据
      columnData: [
        { label: "客户线索Id", width: 180, prop: "customerClueId" },
        { label: "客户名称", width: 250, prop: "customerName" },
        { label: "有效性", width: 120, prop: "isEffective" },
        { label: "手机号", width: 120, prop: "mobile" },
        { label: "车牌", width: 100, prop: "licensePlate" },
        { label: "vin", width: 200, prop: "vin" },
        { label: "一级来源", width: 180, prop: "firstChannel" },
        { label: "二级来源", width: 180, prop: "secondChannel" },
        { label: "终端来源", width: 120, prop: "terminalSource" },
        { label: "注册日期", width: 120, prop: "registrationDate" },
        { label: "下次保险到期日期", width: 120, prop: "nextExpireDate" },
        { label: "下个周期保险线索生成日期", width: 200, prop: "nextClueGenerateDate" },
        { label: "住址", width: 300, prop: "address" },
        { label: "创建时间", width: 180, prop: "ctime" },
        { label: "修改时间", width: 180, prop: "mtime" },
        { label: "创建人", width: 250, prop: "creator" },
        { label: "修改人", width: 250, prop: "updator" }
      ],
      bxClueFirstChannels:[],
      bxClueSecondChannels:[],
      customerDetail:{},
      customerEditForm:{
        vehicleLicense:'',
        customerName: '',
        mobile: '',
        vin: '',
        brand: '',
        vehicleSeries: '',
        vehicleProductYear: '',
        vehicleType: '',
        engineNumber: '',
        issueDate: '',
        useNature: '',
        issueAuthority:'',
        address: '',
        modeCode: '',
        customerClueId: '',
        registrationDate: ''
      },
      ocrResult: null,
      vehicleModel: {},
      modelCode: '',
    };
  },
  //监听属性 类似于data概念
  computed: {
    row() {
      return row;
    }
  },
  created() {
    this.getBxClueSecondChannels();
    this.getBxClueFirstChannels();
  },
  //监控data中的数据变化
  watch: {},
  mounted() {
    this.initEcharts();
  },
  //方法集合
  methods: {
    right,
    emptyToLine,
    // 页面列表翻页
    pageHandler(params, num) {
      // 获取用户列表
      this.getClientList(params, num ? num : params.pageNum, params.pageSize);
    },
     initEcharts(){
       this.$store.dispatch('bxClue/getClueCustomerDashboard',{}).then(res => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('dashboard'));
        // 绘制图表
        myChart.setOption( {
          color:['#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc'],
          title: {
            text: '终端线索数自然月统计'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['系统','IOS', '安卓', '微信', '门店通']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: res.data.days
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
            min: 0,
            max: function (value) {
              return value.max +5;
            }
          },
          series: [
            {
              name: '系统',
              type: 'line',
              data: res.data.systemSeries
            },
            {
              name: 'IOS',
              type: 'line',
              data: res.data.iosSeries
            },
            {
              name: '安卓',
              type: 'line',
              data: res.data.androidSeries
            },
            {
              name: '微信',
              type: 'line',
              data: res.data.wechatSeries
            },
            {
              name: '门店通',
              type: 'line',
              data: res.data.storeAppSeries
            },
          ]
        });
      })

    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        mobile: null,
        customer: null,
        licensePlate: null,
        firstChannel: null,
        secondChannel: null,
        orgName: null,
        expireDate: null,
        state: null
      };
    },

    async getBxClueFirstChannels(){
      await this.$store.dispatch('base/getDictionaryList',"bx_clue_first_channel").then(res => {
        console.info(res)
        this.bxClueFirstChannels = res || []
      })
    },

    async getBxClueSecondChannels(){
      await this.$store.dispatch('base/getDictionaryList',"bx_clue_second_channel").then(res => {
        this.bxClueSecondChannels =  res || []
      })
    },


    getClientList(params, pageNum, pageSize) {
      this.listLoading = true;
      this.$store.dispatch("bxClue/getCustomerlist", {
        ...this.searchData,
        pageNum,
        pageSize
      }).then(res => {
        if (res.code == 0) {
          this.listData = res.data.list;
          params.render(res.data.total);
          this.customerTotal = res.data.total;
        }
        this.listLoading = false;
      });
    },

    openViewFn(row){
      this.$store.dispatch('bxClue/getClueCustomerDetail',{customerClueId: row.customerClueId}).then(res => {
        this.customerDetail =  res.data || {}
      })
      this.drawer = true;
    },


    openEditFn(row){
      this.clearEditForm();
      this.$store.dispatch('bxClue/getClueCustomerDetail',{customerClueId: row.customerClueId}).then(res => {
        Object.keys(res.data).forEach(key => {
          if (key in this.customerEditForm ) {
            this.customerEditForm[key] = res.data[key];
          }
        });
      })
      this.editDrawer = true;
    },

    copyiInfo(){
      var modeList = this.ocrResult.vehicleModelList.filter(item => item.modeCode = this.modelCode);
      console.log(modeList)
      if(modeList){
        this.vehicleModel = modeList[0];
      }
      console.log(this.vehicleModel)
      Object.keys(this.ocrResult).forEach(key => {
        if (key in this.customerEditForm ) {
          this.customerEditForm[key] = this.ocrResult[key];
        }
      });
      this.customerEditForm.customerName = this.ocrResult.owner;
      this.customerEditForm.vehicleProductYear = this.vehicleModel.productYear;
      this.customerEditForm.vehicleSeries = this.vehicleModel.seriesName
      this.customerEditForm.modeCode = this.modelCode
    },

    submitEditForm(){
      this.$store.dispatch('bxClue/bxClueCustomerEdit',this.customerEditForm).then(res => {
        if(res.code == 0){
          this.$message({
            type: "success",
            message: "修改成功"
          });
          this.editDrawer = false;
          this.$router.go(0);
        }else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      })
    },

    clearEditForm(){
      this.customerEditForm= {
        vehicleLicense:'',
        customerName: '',
        mobile: '',
        vin: '',
        brand: '',
        vehicleSeries: '',
        vehicleProductYear: '',
        vehicleType: '',
        engineNumber: '',
        issueDate: '',
        useNature: '',
        issueAuthority:'',
        address: '',
        modeCode: '',
        customerClueId: '',
        registrationDate: ''
      };
    },

    ocr(vehicleLicenseUrl){
      this.$store.dispatch('bxClue/licenseRecognize',{vehicleLicenseUrl}).then(res => {
        this.ocrResult = res.data;
      })
    }
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-drawer {
  min-width: 1100px !important;
}

.page_box {
  padding: 0 20px;

  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }


  .table_top_box {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: -20px;
    box-sizing: border-box;
  }

  .container_box {
    background: #f1f5f7;
    height: 100%;
    padding: 25px;
    box-sizing: border-box;

    .container_box_head {
      .label {
        color: #74788d;
        line-height: 30px;
        font-size: 13px;
      }

      .value {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
      }

      background: #fff;
      margin-bottom: 25px;
      box-shadow: 4px 6px 20px 0 rgba(0, 0, 0, .02);
      padding: 20px;
      box-sizing: border-box;
      height: auto;
    }

    .container_box_main {
      background: #fff;
      box-shadow: 4px 6px 20px 0 rgba(0, 0, 0, .02);
      padding: 0 !important;

      .container_box_main_tabs {
        margin-left: -1px;
      }

      .container_box_main_content {
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
      }

      .client_itinerary {
        padding: 10px 50px;
        box-sizing: border-box;
        max-height: 520px;
        overflow-y: auto;

        .client_itinerary_title {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div:nth-child(2) {
            font-size: 13px;
            color: #1883ff;
            border: 1px solid #1883ff;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 0 5px;
          }
        }

        .client_itinerary_dot {
          margin-left: -19px;
          margin-top: 8px;
          background: #fff;
          padding: 10px 0;
          text-align: center;
          font-size: 12px;
          color: #74788d;
          white-space: pre-line;

          .client_itinerary_dot_icon {
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 5px;
          }

          .client_itinerary_dot_circle {
            width: 11px;
            height: 11px;
            padding-top: 30px;
            position: absolute;
            top: -30px;
            left: -5px;
            background: #fff;

            .circle {
              width: 11px;
              height: 11px;
              border-radius: 100%;
              border: 1px solid #adb5bd;
              background: #f8f9fa;
              box-sizing: border-box;
            }
          }
        }

        .client_itinerary_dot_first {
          margin-top: 23px !important;
        }

        .client_itinerary_dot_html {
          white-space: pre-wrap !important;
          background: #f8f9fa;
          font-size: 13px;
          line-height: 20px;
          padding: 12px;
          box-sizing: border-box;
          margin: 10px 0;
          color: #505d69;
        }

        .client_itinerary_dot_operator {
          display: flex;
          align-items: center;
          font-size: 13px;

          img {
            width: 28px;
            height: 28px;
            display: block;
            border-radius: 100%;
            margin-right: 10px;
          }

          > div:nth-child(2) {
            margin-right: 20px;
            color: #505d69;
          }

          > div:nth-child(3) {
            color: #adb5bd;
          }
        }
      }
    }
  }
}

::v-deep .el-dialog__body {
  padding-top: 0 !important;
}

::v-deep .el-descriptions__header {
  margin: 10px 0px !important;
}

::v-deep .el-descriptions__title {
  font-size: 13px !important;
}

::v-deep .el-descriptions__body {
  padding: 0 20px !important;
}

::v-deep .el-timeline-item__tail {
  border-left: 1px solid #dcdee0 !important;
  left: 0 !important;
}

::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: initial !important;
}

::v-deep .el-timeline:last-child .el-timeline-item:last-child .el-timeline-item__tail {
  display: none !important;
}

::v-deep .el-timeline {
  margin-bottom: 30px !important;
}

::v-deep .el-timeline-item__timestamp {
  font-size: 16px !important;
  color: #505d69 !important;
  font-weight: bold;
  margin-bottom: 15px;
}

::v-deep .el-timeline-item__wrapper {
  padding-left: 50px !important;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 3px solid transparent;
  box-sizing: border-box;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-left: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-top: 3px solid #2278f5;
  box-sizing: border-box;
}

::v-deep .el-input-group__prepend {
  background-color: #fff !important;
}

::v-deep .el-table__header thead tr th:first-of-type {
  padding-left: 0;
}

.el-select {
  width: 110px !important;
}

.billboards{
  background: #fff;
}
</style>
